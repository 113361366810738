export const formatTime = (inpDate) => {
    const date = new Date(inpDate);
    let now = new Date();        
    const oneDay = 60 * 60 * 24 * 1000;
    const compareDatesBoolean = (now - date) > oneDay;
    if(compareDatesBoolean){

        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        
        if(((new Date()) - date) / (1000 * 3600 * 24 * 365) > 1){
            return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} at ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        }else{
            return `${months[date.getMonth()]} ${date.getDate()} at ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`
        }

    }else{
        return processTime(date);
    }
  }

  export const processTime = (date) => {
    const formatter = new Intl.RelativeTimeFormat('en');
    const ranges = {
      years: 3600 * 24 * 365,
      months: 3600 * 24 * 30,
      weeks: 3600 * 24 * 7,
      days: 3600 * 24,
      hours: 3600,
      minutes: 60,
      seconds: 1
    };
    const secondsElapsed = (date.getTime() - Date.now()) / 1000;
    for (let key in ranges) {
      if (ranges[key] < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / ranges[key];
        return formatter.format(Math.round(delta), key);
      }
    }
    return 'few seconds ago';
  }



  export const getSentenceFromCamelCase = (message) => {
    let pattern = /[A-Za-z]/g;
    let messages = message.match(pattern);
    let errorMessage = "";
    for (let i = 0; i < messages.length; i++) {
      errorMessage +=
        messages[i] === messages[i].toUpperCase()
          ? " " + messages[i].toLowerCase()
          : messages[i];
    }
    return (errorMessage[0].toUpperCase() + errorMessage.slice(1)).trim();
  };