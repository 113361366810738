import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useLoginResult } from "../State/LoginResultContext";
import { useEventResult } from "../State/EventResultContext";
import { useNavigate } from "react-router-dom";
import { useErrorRenderer } from "../Hooks/useErrorRenderer";
import Snackbar from "../Components/SnackBar";
import { API_URL } from "../utils/constants";
import "./OrgEvents.css";
import Navbar from "../Components/Navbar";

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "center",
    format: (value) => value,
  },
  {
    id: "location",
    label: "Location",
    minWidth: 100,
    align: "center",
    format: (value) => value,
  },
  {
    id: "createdDate",
    label: "Created",
    minWidth: 170,
    align: "center",
    format: (value) => new Date(value).toLocaleString(),
  },
  {
    id: "numConnected",
    label: "No Connected",
    minWidth: 170,
    align: "center",
    format: (value) => value,
  },
];

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [input, setInput] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("desc");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage, isError, setIsError, resetErrorState] =
    useErrorRenderer();
  const [rows, setRows] = useState([]);
  const [meta, setMeta] = useState({
    events: [],
    page: 1,
    pages: 0,
    total: 0,
    per_page: 0,
    order_by: "desc",
  });
  const { loginResults } = useLoginResult();
  const { updateeventResults } = useEventResult();
  const navigate = useNavigate();

  const handleStreamEvent = (eventId) => {
    const event = rows.find((evt) => evt.id === eventId);
    updateeventResults(event);
    navigate("/eventdetails");
  };

  const fetchOrgEventsHistory = async (p) => {
    setLoading(true);
    const currentPage = p || page;
    var token = "";
    if (loginResults !== null) {
      token = loginResults.token;
    }

    try {
      const response = await fetch(
        API_URL +
        `/events/${loginResults.id}/events-history?page=${currentPage}&per_page=${rowsPerPage}&query=${input}&order_by=${orderBy}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      setLoading(false);
      if (response.status === 200) {
        setMeta(data);
        setRows(data.events);
      } else {
        setMessage(data.message);
        setIsError(true);
        resetErrorState();
      }
    } catch (error) {
      setMessage(error.message);
      setIsError(true);
      setLoading(false);
      resetErrorState();
    }
  };

  useEffect(() => {
    fetchOrgEventsHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSearch = async (e) => {
    e.preventDefault();
    if (input.length > 0) {
      await fetchOrgEventsHistory(1);
    } else {
      alert("please enter name or location to search");
    }
  };

  const handleDelete = async (eventId) => {
    var token = "";
    if (loginResults !== null) {
      token = loginResults.token;
    }

    try {
      const response = await fetch(API_URL + `/events/${eventId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        setRows(rows.filter((event) => event.id !== eventId));
      } else {
        setMessage(data.message);
        setIsError(true);
        resetErrorState();
      }
    } catch (error) {
      setMessage(error.message);
      setIsError(true);
      resetErrorState();
    }
  };

  const createEvent = (e) => {
    e.preventDefault();
    navigate("/events");
  };

  const myEvents = (e) => {
    e.preventDefault();
    navigate("/my-events");
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    fetchOrgEventsHistory(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const searchFilter = (e) => {
    e.preventDefault();
    const val = e.target.value;
    setInput(String(val));
  };

  return (
    <div>
      <Navbar />
      <div id="event-list-table">
        <h1 className="pb-3">My Event History</h1>
        <div className="page-actions pb-3">
          <button onClick={createEvent}>Create a new event</button>
          <button onClick={myEvents}>My Event List</button>
        </div>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <input
                      style={{ paddingLeft: "15px", background: "#efefef" }}
                      type="text"
                      name="name"
                      placeholder="search by name or location"
                      onChange={(e) => searchFilter(e)}
                    />
                  </TableCell>
                  <TableCell>
                    <lable>Created Date:</lable>
                    <select
                      value={orderBy}
                      onChange={(e) => setOrderBy(e.target.value)}
                    >
                      <option value={"desc"}>Descending</option>
                      <option value={"asc"}>Ascending</option>
                    </select>
                  </TableCell>
                  <TableCell>
                    <button onClick={handleOnSearch}>submit</button>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>

                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bolder",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {loading ? (
                <p>Loading...</p>
              ) : (
                <TableBody>
                  {rows.length > 0
                    ? rows.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format(value)}
                              </TableCell>
                            );
                          })}

                          <TableCell align="center">
                            <button
                              style={{ backgroundColor: "#007bff" }}
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Are you sure you wish to start streaming this event now ?"
                                  )
                                )
                                  handleStreamEvent(row.id);
                              }}
                            >
                              Stream
                            </button>
                          </TableCell>
                          <TableCell align="center">
                            <button
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Are you sure you wish to delete this event from the list ?"
                                  )
                                )
                                  handleDelete(row.id);
                              }}
                            >
                              Delete
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                    : ""}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={meta.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Snackbar isError={isError} message={message} />
      </div>
    </div>
  );
}
