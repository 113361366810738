import { useState } from "react";

export function useErrorRenderer() {
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const resetErrorState = () => {
    setTimeout(() => {
      setMessage("");
      setIsError(false);
    }, 3000);
  };

  return [message, setMessage, isError, setIsError, resetErrorState];
}
