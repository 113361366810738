import React from 'react';
import PropTypes from 'prop-types';
import './StepIndicator.css'; // Import your CSS file for styling

const StepIndicator = ({ currentStep }) => {
  return (
    <div className="mx-auto flex justify-center step-indicator text-center">
      <div className={`dot ${currentStep >= 1 ? 'active' : ''}`}></div>
      <div className={`line ${currentStep >= 2 ? 'active' : ''}`}></div>
      <div className={`dot ${currentStep >= 2 ? 'active' : ''}`}></div>
      <div className={`line ${currentStep === 3 ? 'active' : ''}`}></div>
      <div className={`dot ${currentStep === 3 ? 'active' : ''}`}></div>
    </div>
  );
};

StepIndicator.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default StepIndicator;
