import { useState } from "react";
import Navbar from "../Components/Navbar";
import { AgCharts } from "ag-charts-react";
import { useLoginResult } from "../State/LoginResultContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { API_URL } from "../utils/constants";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const navigate = useNavigate();
  const { loginResults, updateloginResults } = useLoginResult();
  const [usageAnalytics, setUsageAnalytics] = useState(null);

  useEffect(() => {
    const fetchUserAnalytics = async () => {
      const url = API_URL + `/users/${loginResults.id}/usageAnalytics`;
      const token = loginResults.token;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.status === 200) {
          setUsageAnalytics(data);
          const usedHours = data?.usageAnalytics?.hoursUsed || 0;
          const totalHours = data.totalHours || 30;
          const _option = {
            data: [
              {
                asset: "",
                amount: usedHours,
                fill: "#fefefe",
              },
              { asset: "", amount: totalHours - usedHours, fill: "green" },
            ],
            title: {
              text: "Current Monthly Usage",
            },
            tooltip: {
              enabled: false,
            },
            legend: {
              enabled: false,
            },
            series: [
              {
                type: "donut",
                calloutLabelKey: "asset",
                angleKey: "amount",
                innerRadiusRatio: 0.9,
                innerLabels: [
                  {
                    text: `${usedHours.toFixed(1)}/${totalHours}`,
                    fontWeight: "bold",
                    fontSize: 20,
                  },
                  {
                    text: "Hours Used",
                    spacing: 4,
                    fontSize: 20,
                    color: "green",
                  },
                ],
              },
            ],
          };
          setOptions(_option);
        } else {
          console.log("usageAnalytics response:", data);
        }
      } catch (error) {
        console.log("usageAnalytics error:", error);
      }
    };

    fetchUserAnalytics();
  }, [loginResults.id, loginResults.token]);

  const onLogout = () => {
    updateloginResults(null);
    navigate("/");
  };
  const [options, setOptions] = useState(null);

  return (
    <div className="bg-white">
      <Navbar />
      <main>
        <div className="mx-auto min-w-xl max-w-7xl px-6 sm:mt-4 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
              {loginResults?.organization}
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8">
            Customer Status:{" "}
            <span className="text-gray-700">
              {loginResults?.customerStatus}
            </span>
          </p>

          <div className="w-full">
            <div className="isolate mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-1 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-1">
              <div
                className={classNames(
                  "mx-auto ring-1 ring-gray-200",
                  "rounded-3xl max-w-2xl sm:p-8 p-2 w-full"
                )}
              >
                {options && <AgCharts options={options} />}
              </div>
              <div className="mx-auto grid max-w-2xl grid-cols-2 gap-8 ">
                <div
                  className={classNames(
                    "col-span-1 mx-auto ring-1 ring-gray-200",
                    "rounded-3xl p-8"
                  )}
                >
                  <h2
                    className={classNames(
                      "text-gray-900",
                      "text-2xl font-semibold leading-8 text-center"
                    )}
                  >
                    {usageAnalytics?.usageAnalytics?.numOfEvents || "-"}
                  </h2>
                  <div className="col-span-1">Total # of Events in Month</div>
                </div>
                <div
                  className={classNames(
                    "col-span-1 mx-auto ring-1 ring-gray-200",
                    "rounded-3xl p-8"
                  )}
                >
                  <h2
                    className={classNames(
                      "text-gray-900",
                      "text-2xl font-semibold leading-8 text-center"
                    )}
                  >
                    {usageAnalytics?.usageAnalytics?.numOfConnections || "-"}
                  </h2>
                  <div className="col-span-1">
                    Total # of Connections in Month
                  </div>
                </div>
                <div className="col-span-full">
                  <div className="mx-auto my-4 border-b border-gray-200"></div>
                </div>
                <div className="sm:col-span-1 col-span-1 text-center">
                  <a href="/profile">
                    <button className="col-span-full">Edit Profile</button>
                  </a>
                </div>
                <div className="sm:col-span-1 col-span-1 text-center">
                  <a href="/contact-us">
                    <button className="col-span-full">Contact US</button>
                  </a>
                </div>
                <button onClick={onLogout} className="my-4 col-span-full">
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
