import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginResult } from "../State/LoginResultContext";
import { API_URL } from "../utils/constants";
import { useErrorRenderer } from "../Hooks/useErrorRenderer";

export default function Login() {
  const [setMessage, setIsError, resetErrorState] =
    useErrorRenderer();

  const navigate = useNavigate();
  const { loginResults, updateloginResults } = useLoginResult();
  const [fullName, setFullName] = useState(loginResults?.fullName);
  const [organization, setOrganization] = useState(loginResults?.organization);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/users/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginResults.token}`,
        },
        body: JSON.stringify({
          id: loginResults.id,
          fullName: fullName,
          organization: organization,
        }),
      });

      const data = await response.json();
      if (response.status === 200) {
        updateloginResults({
          ...loginResults,
          fullName: fullName,
          organization: organization,
        });
        navigate("/my-events");
      } else {
        setMessage(data.message);
        setIsError(true);
        resetErrorState();
      }
    } catch (error) {
      setMessage(error.message);
      setIsError(true);
      resetErrorState();
    }
  };

  return (
    <div className="min-h-full flex-col">
      <div className="flex min-h-dvh flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Edit Profile
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm flex justify-center">
          <div className="flex items-center justify-center h-20 w-20 rounded-full bg-[#5701AE] text-white text-2xl font-bold">
            {loginResults?.fullName?.charAt(0)}
          </div>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit} method="POST" className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-white"
              >
                Full Name
              </label>
              <div className="mt-2">
                <input
                  id="full_name"
                  name="full_name"
                  type="text"
                  onChange={(e) => setFullName(e.target.value)}
                  value={fullName}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="Organization"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Organization
                </label>
              </div>
              <div className="mt-2">
                <input
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setOrganization(e.target.value)}
                  value={organization}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Update Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
