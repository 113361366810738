import React, { useState } from "react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import moment from "moment/moment";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useLoginResult } from "../State/LoginResultContext";
import { useEventResult } from "../State/EventResultContext";
import { useNavigate } from "react-router-dom";
import SeymourText from "../Components/SeymourText";
import "./EventForm.css";
import StepIndicator from "../Components/StepIndicator";
import { API_URL } from "../utils/constants";
import { useErrorRenderer } from "../Hooks/useErrorRenderer";
import Snackbar from "../Components/SnackBar";
import Navbar from "../Components/Navbar";
import TextField from "../Components/TextField";

const times = Array.from({ length: 96 }, (_, i) => {
  const hour = Math.floor(i / 4);
  const minute = (i % 4) * 15;
  const time = new Date(0, 0, 0, hour, minute);
  return time.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
});

function EventForm() {
  const [selected, setSelected] = useState("12:00 AM");
  const [message, , isError, setIsError, resetErrorState] = useErrorRenderer();
  const [eventName, setEventName] = useState("");
  const [keywords, setKeywords] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventDate, setEventDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [errors, setErrors] = useState("");
  const [isRecurring, setIsRecurring] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState([]);

  const { loginResults } = useLoginResult();
  const { updateeventResults } = useEventResult();
  const navigate = useNavigate();

  const onChangeKeywords = (_name, value) => {
    setKeywords(value);
  };
  const validationHandler = (_name, error) => {
    setErrors(error);
  };

  const createEvent = async (e) => {
    e.preventDefault();
    var token = "";
    if (loginResults !== null) {
      token = loginResults.token;
    }

    let arr = keywords.split(" ");
    if (arr.length > 100) {
      alert(
        "We can only support 100 keywords at the moment. Please reduce your keywords"
      );
      return;
    }

    const dateOnly = eventDate.split(" ")[0];
    const event = {
      name: eventName,
      location: eventLocation,
      scheduledToStartAt: `${dateOnly} ${selected}`,
      recurring: isRecurring,
      daysOfTheWeek: daysOfWeek,
      keywords: keywords,
    };
    try {
      const response = await fetch(API_URL + "/events", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(event),
      });
      const data = await response.json();
      if (response.status === 200) {
        updateeventResults(data);
        navigate("/eventdetails");
      } else {
        setIsError(true);
        resetErrorState();
      }
    } catch (error) {
      // setMessage(error.message);
      setIsError(true);
      resetErrorState();
    }
  };

  return (
    <div>
      <Navbar />
      <div className="row">
        <StepIndicator currentStep={1} />
      </div>
      <div className="eventInfo">
        <SeymourText props={"Event Info"} />
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <form onSubmit={createEvent}>
          <div className="grid grid-cols-1 gap-x-2 gap-y-8 sm:grid-cols-2">
            <div className="col-span-1">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-white"
              >
                Event Name
              </label>
              <div className="mt-2">
                <input
                  id="Name"
                  name="Name"
                  type="text"
                  required
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-white"
              >
                Event Location
              </label>
              <div className="mt-2">
                <input
                  id="Location"
                  name="Location"
                  type="text"
                  required
                  value={eventLocation}
                  onChange={(e) => setEventLocation(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-white"
              >
                Event Date
              </label>

              <DatePicker
                selected={eventDate}
                minDate={new Date()}
                onSelect={(date) => {
                  console.log("🚀 ~ EventForm ~ date:", date, typeof date);
                  const _date = moment(date).format("YYYY-MM-DD HH:mm:ss");
                  console.log("🚀 ~ EventForm ~ _date:", _date);
                  setEventDate(_date);
                }}
                wrapperClassName="w-full"
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="col-span-1">
              <div className="">
                <Listbox value={selected} onChange={setSelected}>
                  <Label className="block text-sm font-medium leading-6 text-white">
                    Event Time
                  </Label>
                  <div className="relative mt-2">
                    <ListboxButton className="relative w-full cursor-default rounded-md !bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <span className="block truncate">{selected}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-gray-400"
                        />
                      </span>
                    </ListboxButton>

                    <ListboxOptions
                      transition
                      className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                    >
                      {times.map((item) => (
                        <ListboxOption
                          key={item}
                          value={item}
                          className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                        >
                          <span className="block truncate font-normal group-data-[selected]:font-semibold">
                            {item}
                          </span>

                          <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                            <CheckIcon aria-hidden="true" className="h-5 w-5" />
                          </span>
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <input
              type="checkbox"
              checked={isRecurring ?? false}
              onChange={(e) => setIsRecurring(e.target.checked)}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-600"
            />
            <label className="ml-2 ms-2 text-sm font-medium text-white">
              Recurring (optional)
            </label>
          </div>
          {isRecurring && (
            <div className="my-4">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-white"
              >
                Days of week
              </label>
              <div className="mb-4 flex justify-between">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                  (day) => (
                    <div key={day}>
                      <input
                        type="checkbox"
                        checked={daysOfWeek.includes(day)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDaysOfWeek([...daysOfWeek, day]);
                          } else {
                            setDaysOfWeek(daysOfWeek.filter((d) => d !== day));
                          }
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-600"
                      />
                      <label className="mr-2 ms-2 text-sm font-medium text-white">
                        {day}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          <TextField
            className={`bg-[#4a4759] text-white ${
              !errors || errors === "" ? "" : "error-input"
            }`}
            onChange={onChangeKeywords}
            value={keywords}
            name={"details"}
            error={errors}
            isRequired={false}
            validationHandler={validationHandler}
            label={"Context Keywords (enter keywords seperated by spaces)*"}
            helperText={"Add keywords (optional)"}
            rows={"8"}
          />

          <button className="registerButton3" type="submit">
            <div>Create event</div>
          </button>
        </form>
      </div>

      <Snackbar isError={isError} message={message} />
    </div>
  );
}

export default EventForm;
