import React from 'react';
import './EventTable.css';
// import { formatTime } from '../utils';

const EventTable = ({ events, onStreamEvent, onDelete }) => {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) => (
            <tr key={event.id}>
              <td>{event.name}</td>
              <td>{event.location}</td>
              <td>{new Date(event.createdDate).toLocaleString()}</td>
              <td>
                <button
                  onClick={(e) => { if (window.confirm('Are you sure you wish to start streaming this event now ?')) onStreamEvent(event.id) } }
                  >Start Streaming</button>
                <button
                  onClick={(e) => { if (window.confirm('Are you sure you wish to delete this event from the list ?')) onDelete(event.id) } }
                  >Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventTable;
