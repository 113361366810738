import React from 'react'
import ReactDOM from 'react-dom/client'
import { GoogleOAuthProvider } from '@react-oauth/google'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import { LoginResultsProvider } from './State/LoginResultContext'
import { EventResultsProvider } from './State/EventResultContext'
import { REACT_APP_GOOGLE_CLIENT_ID } from './utils/constants'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <LoginResultsProvider>
    <EventResultsProvider>
      <React.StrictMode>
        <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
          <Router>
            <App />
          </Router>
        </GoogleOAuthProvider>
      </React.StrictMode>
    </EventResultsProvider>
  </LoginResultsProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
