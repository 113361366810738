import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginResult } from "../State/LoginResultContext";

export default function Signout() {
  const { loginResults, setloginResults } = useLoginResult();
  const navigate = useNavigate();

  useEffect(() => {
    if (loginResults === null) return;
    if (setloginResults) {
      setloginResults(null);
    }
    navigate("/");
  }, [loginResults, navigate, setloginResults]);

  return <></>;
}
