import React, { useState, useEffect } from "react";
import EventTable from "../Components/EventTable";
import { useLoginResult } from "../State/LoginResultContext";
import { useEventResult } from "../State/EventResultContext";
import { useNavigate } from "react-router-dom";
import { useErrorRenderer } from "../Hooks/useErrorRenderer";
import Snackbar from "../Components/SnackBar";
import { API_URL } from "../utils/constants";
import "./OrgEvents.css";
import Navbar from "../Components/Navbar";

const OrgEvents = () => {
  const [message, setMessage, isError, setIsError, resetErrorState] =
    useErrorRenderer();
  const [events, setEvents] = useState([]);
  const { loginResults } = useLoginResult();
  const { updateeventResults } = useEventResult();
  const navigate = useNavigate();

  const handleStreamEvent = (eventId) => {
    const event = events.find((evt) => evt.id === eventId);
    updateeventResults(event);
    navigate("/eventdetails");
  };

  useEffect(() => {
    if (loginResults.token) {
      const fetchOrgEvents = async () => {
        var token = "";
        if (loginResults !== null) {
          token = loginResults.token;
        }

        try {
          const response = await fetch(
            API_URL + `/events/${loginResults.id}/org-events`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data = await response.json();
          if (response.status === 200) {
            setEvents(data);
          } else {
            setMessage(data.message);
            setIsError(true);
            resetErrorState();
          }
        } catch (error) {
          setMessage(error.message);
          setIsError(true);
          resetErrorState();
        }
      };
      fetchOrgEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResults.token]);

  const handleDelete = async (eventId) => {
    var token = "";
    if (loginResults !== null) {
      token = loginResults.token;
    }

    try {
      const response = await fetch(
        API_URL + `/events/${eventId}/take-off-list`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        setEvents(events.filter((event) => event.id !== eventId));
      } else {
        setMessage(data.message);
        setIsError(true);
        resetErrorState();
      }
    } catch (error) {
      setMessage(error.message);
      setIsError(true);
      resetErrorState();
    }
  };

  const createEvent = (e) => {
    e.preventDefault();
    navigate("/events");
  };

  const myEvents = (e) => {
    e.preventDefault();
    navigate("/my-event-history");
  };

  return (
    <div>
      <Navbar />
      <div id="event-list-table">
        <h1 className="text-center font-bold text-2xl">My Event List</h1>
        <div className="page-actions">
          <button onClick={createEvent}>Create a new event</button>
          <button onClick={myEvents}>My Event History</button>
        </div>
        {events.length > 0 ? (
          <EventTable
            events={events}
            onStreamEvent={handleStreamEvent}
            onDelete={handleDelete}
          />
        ) : (
          <h4 className="my-4 text-center text-2xl">
            Event list is currently empty.
          </h4>
        )}
        <Snackbar isError={isError} message={message} />
      </div>
    </div>
  );
};

export default OrgEvents;
