import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useLoginResult } from "../State/LoginResultContext";
import "./Admin.css";
import { API_URL } from "../utils/constants";
import { useErrorRenderer } from "../Hooks/useErrorRenderer";
import Snackbar from "../Components/SnackBar";

const Admin = () => {
    const [message, setMessage, isError, setIsError, resetErrorState] = useErrorRenderer();
    const [showMenu, setShowMenu] = useState(false);
    const { loginResults, setloginResults } = useLoginResult();
    const [events, setEvents] = useState([]);
    const id5 = loginResults.id;
    const token5 = loginResults.token;

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await fetch(`${API_URL}/events/${id5}/org-events`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token5}`,
                    },
                });

                const data = await response.json();
                if (response.status === 200) {
                    setEvents(data);
                } else {
                    setMessage(data.message);
                    setIsError(true);
                    resetErrorState();
                }
            } catch (error) {
                setMessage(error.message);
                setIsError(true);
                resetErrorState();
            }
        };
        fetchEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const navigate = useNavigate();
    const logout = () => {
        setloginResults(null);
        navigate("/");
    };
    return (
        <div>
            <div className="header">
                <div className="seymour1">Seymour</div>
                <FontAwesomeIcon
                    icon={faEllipsisV}
                    className="icon"
                    color="white"
                    onClick={toggleMenu}
                />
                {showMenu && (
                    <div className="menu">
                        <div className="menuItem" onClick={() => navigate("/my-events")}>
                            Events
                        </div>
                        <div className="menuItem" onClick={() => navigate("/admin")}>
                            Admin
                        </div>
                        <div className="menuItem" onClick={logout}>
                            Log out
                        </div>
                    </div>
                )}
            </div>
            <div className="columnAdmin">
                <div className="rowAdmin">
                    <h2 className="name">Hi {loginResults.fullName}</h2>
                    <div className="proPlan">
                        <div className="proPlantext">Free Trial</div>
                    </div>
                </div>
                <div className="hoursLeft">Free trial includes 10 hours</div>
            </div>
            <table className="table" style={{ padding: "20px" }}>
                <thead>
                    <tr>
                        <th
                            style={{
                                color: "white",
                                maxWidth: "180px",
                                display: "flex",
                                justifyContent: "start",
                                fontWeight: 500,
                                paddingRight: "200px",
                            }}
                        >
                            Event
                        </th>
                        <th style={{ color: "white", width: "100px", fontWeight: 500 }}>
                            Duration
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {events.map((event, index) => {
                        const startedAt = event.startedAt;
                        const endedAt = event.endedAt;
                        const duration = Math.round(
                            (new Date(endedAt) - new Date(startedAt)) / (1000 * 60)
                        );
                        if (isNaN(duration)) {
                            return null;
                        }
                        return (
                            <>
                                <tr>
                                    <td colSpan="2" style={{ borderTop: "1px solid white" }}></td>
                                </tr>
                                <tr key={index}>
                                    <td
                                        style={{
                                            color: "white",
                                            maxWidth: "180px",
                                            padding: "20px 0px",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        {event.name}
                                    </td>
                                    <td style={{ color: "white", width: "100px" }}>
                                        {duration} mins
                                    </td>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
            <Snackbar isError={isError} message={message} />
        </div>
    );
};

export default Admin;
