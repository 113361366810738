export const API_URL = 'https://sonic-script-api.onrender.com';
export const API_SOCKET_URL = 'wss://sonic-script-api.onrender.com';

// export const API_URL = 'http://localhost:8080';
// export const API_SOCKET_URL = 'ws://localhost:8080';

export const DEEPGRAM_SOCKET_URL = 'wss://api.deepgram.com/v1/listen';
export const DEEPGRAM_API_TOKEN = '70b26068d675cc6fcc5292f4b93f7febda5be2bc';
export const STRIPE_API_KEY = 'pk_test_51OuOhSCDUiIAFLBNzzPN2llIiIXFMqSagSW5m3uE49qEpPhcVIesf6QtEnVsj5DJaQmi6tTNgzEI6JTW4fbXEz7Z00M6bc8Sbv';
export const REACT_APP_GOOGLE_CLIENT_ID = '587904854621-g3rut3jqc1234lknggk76umsu0bd3aiq.apps.googleusercontent.com'
//53 languages variants 
export const SUPPORTED_LANGUAGES = {
    "bg":"Bulgarian",
    "ca":"Catalan",
    "zh":"Chinese (China, Simplified)",
    "zh-CN":"(Mandarin, PRC)",
    "zh-Hans":"(Mandarin, Simplified)",
    "zh-TW":"Chinese (Mandarin, Taiwanese)",
    "zh-Hant":"Chinese (Mandarin, Traditional)",
    "zh-HK":"Chinese (Cantonese, Hong Kong)",
    "cs":"Czech", 
    "da":"Danish",
    "da-DK":"Danish (Denmark)",
    "nl":"Dutch",
    "en":"English", 
    "en-US":"English (United States)",
    "en-GB": "English (United Kingdom)",
    "en-AU":"English (Australia)",
    "en-NZ":"English (New Zealand)",
    "en-IN":"English (India)",
    "et":"Estonian",
    "fi":"Finnish",
    "nl-BE":"Flemish",
    "fr":"French",
    "fr-CA":"French (Canada)",
    "de":"German",
    "de-CH":"German (Switzerland)",
    "el":"Greek",  
    "hi":"Hindi", 
    "hu":"Hungarian",
    "id":"Indonesian",
    "it":"Italian", 
    "ja":"Japanese",
    "ko":"Korean",
    "ko-KR":"Korean (South Korea)",
    "lv":"Latvian", 
    "lt":"Lithuanian",
    "ms":"Malay",
    "no":"Norwegian",
    "pl":"Polish",
    "pt":"Portuguese",
    "pt-PT":"Portuguese (Portugal)",
    "pt-BR":"Portuguese (Brazil)", 
    "ro":"Romanian", 
    "ru":"Russian", 
    "sk":"Slovak",
    "es":"Spanish", 
    "es-419":"Spanish (Latin America and the Caribbean)", 
    "sv":"Swedish", 
    "sv-SE":"Swedish (Sweden)",  
    "th":"Thai", 
    "th-TH":"Thai (Thailand)", 
    "tr":"Turkish",
    "uk":"Ukrainian",
    "vi":"Vietnamese"  
    
};
