import Login from "./Login";
import Register from "./Register";
import { React, useState } from "react";

const LoginRegister = () => {
  const [formType, setFormType] = useState("login");

  const switchForm = (type) => {
    setFormType(type);
  };

  return (
    <>
      {formType === "register" ? (
        <Register switchForm={switchForm} />
      ) : (
        <Login switchForm={switchForm} />
      )}
    </>
  );
};

export default LoginRegister;
