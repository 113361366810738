import React, { createContext, useContext, useEffect, useReducer } from 'react';

const initialState = null;

let reducer = (_info, newInfo) => {
  if (newInfo === null) {
    localStorage.removeItem("yld");
    return initialState;
  }
  return newInfo;
};


const localState = JSON.parse(localStorage.getItem("yld"));
const LoginResultContext = createContext();

export const LoginResultsProvider = ({ children }) => {
  const [loginResults, setloginResults] = useReducer(reducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem("yld", JSON.stringify(loginResults));
  }, [loginResults]);


  const updateloginResults = (results) => {
    setloginResults(results);
  };

  return (
    <LoginResultContext.Provider value={{ loginResults, updateloginResults }}>
      {children}
    </LoginResultContext.Provider>
  );
};

export const useLoginResult = () => {
  return useContext(LoginResultContext);
};





