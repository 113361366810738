import React from "react";
import "./SnackBarStyling.css";
import {
  IgrSnackbar,
  IgrButtonModule,
  IgrSnackbarModule,
} from "igniteui-react";
import "igniteui-webcomponents/themes/light/bootstrap.css";

IgrButtonModule.register();
IgrSnackbarModule.register();

export default class Snackbar extends React.Component {
  snackbarRef = null;

  state = {
    open: false,
  };

  onSnackbarRef = (snackbar) => {
    if (!snackbar) {
      return;
    }
    this.snackbarRef = snackbar;
  };

  componentDidUpdate(prevState, prevProps) {
    if (this.props.isError === true) {
      //    this.setState({open: true });
      this.onShowButtonClicked();
    } else if (this.props.isError === false) {
      // this.setState({open: false });
      this.onCloseButtonClicked();
    }
  }

  onShowButtonClicked = () => {
    if (this.snackbarRef) {
      this.snackbarRef.show();
    }
  };

  onCloseButtonClicked = () => {
    if (this.snackbarRef) {
      this.snackbarRef.hide();
    }
  };

  render() {
    return (
      <div className="container sample">
        <IgrSnackbar ref={this.onSnackbarRef}>
          <span>{this.props.message}</span>
        </IgrSnackbar>
      </div>
    );
  }
}
