import EventForm from './Event/EventForm';
import { Routes, Route, Navigate } from "react-router-dom";
import LoginRegister from './Login/LoginRegister';
import EventDetails from './Event/EventDetails';
import OrgEvents from './Event/OrgEvents';
import { useLoginResult } from './State/LoginResultContext';
import Checkout from './Checkout/Checkout';
import Admin from './Admin/Admin';
import PaginatedEvents from './Event/PaginatedEvents';
import Overview from './Event/Overview'
import Profile from './Event/Profile';
import ContactUS from './Event/ContactUS';
import Signout from './Login/Signout';

function App() {
  const { loginResults } = useLoginResult();

  const isLoginNull = () => {
    return loginResults === null;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/loginregister" />} />
        <Route path="/loginregister" element={<LoginRegister />} />
        <Route path="/events" element={isLoginNull() ? <Navigate to="/loginregister" /> : <EventForm />} />
        <Route path="/my-events" element={isLoginNull() ? <Navigate to="/loginregister" /> : <OrgEvents />} />
        <Route path="/my-event-history" element={isLoginNull() ? <Navigate to="/loginregister" /> : <PaginatedEvents />} />
        <Route path="/eventdetails" element={isLoginNull() ? <Navigate to="/loginregister" /> : <EventDetails />} />
        <Route path="/checkout" element={isLoginNull() ? <Navigate to="/loginregister" /> : <Checkout />} />
        <Route path="/admin" element={isLoginNull() ? <Navigate to="/loginregister" /> : <Admin />} />
        <Route path="/overview" element={isLoginNull() ? <Navigate to="/loginregister" /> : <Overview />} />
        <Route path="/profile" element={isLoginNull() ? <Navigate to="/loginregister" /> : <Profile />} />
        <Route path="/contact-us" element={isLoginNull() ? <Navigate to="/loginregister" /> : <ContactUS />} />
        <Route path="/signout" element={isLoginNull() ? <Navigate to="/loginregister" /> : <Signout />} />
      </Routes>
    </div>
  );
}
export default App;
