import React from "react";
import PropTypes from "prop-types";
import { getSentenceFromCamelCase } from "../utils";
import './TextField.css';

const TextField = ({
  checked,
  className,
  disabled,
  error,
  fixLength,
  helperText,
  isRequired,
  isKeywords,
  label,
  minLength,
  maxLength,
  name,
  onChange,
  placeholder,
  style,
  type,
  validationHandler,
  value,
  rows,
  cols,
}) => {
  const onChangeHandler = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    onChange && onChange(name, inputValue);
  };

  const onValidationChange = (event) => {
    if (!validationHandler) return;
    const { value } = event.target;

    let errorMessage = "";
    if (!value && isRequired) {
      errorMessage = `Please enter ${getSentenceFromCamelCase(name)}.`;
    } else if (minLength && value.length < minLength) {
      errorMessage = `${
        name.charAt(0).toUpperCase() + getSentenceFromCamelCase(name).slice(1)
      } must be at least ${minLength} characters long.`;
    } else if (maxLength && value.length > maxLength) {
      errorMessage = `${
        name.charAt(0).toUpperCase() + getSentenceFromCamelCase(name).slice(1)
      } must be ${minLength} characters long.`;
    } else if (fixLength && value.length !== fixLength) {
      errorMessage = `${
        name.charAt(0).toUpperCase() + getSentenceFromCamelCase(name).slice(1)
      } must be ${fixLength} characters.`;
     } else if(isKeywords && value.length > 0){
        const arr = value.split(' ');
        if(arr.length > 100){
            errorMessage = "keywords must be 100 words maximum"
        }
     }
    validationHandler(name, errorMessage);
  };

  return (
    <div className="field-group">
        <label style={{color:"#ffffff"}}>{label}</label>
        <em style={{color:"#ffffff"}}>{helperText}</em>
        <textarea  
            rows={`${rows}`} 
            cols={`${cols}`}
            type={type}
            name={name}
            id={name}
            value={value}
            checked={checked}
            placeholder={placeholder}
            className={className}
            style={style}
            disabled={disabled}
            onChange={onChangeHandler}
            onBlur={onValidationChange}
        >
        </textarea>
      
      {error ? <span className='text-danger fs-12'>{error}</span> : null}
    </div>
  );
};

TextField.defaultProps = {
  checked: false,
  className: "",
  disabled: false,
  isKeywords: false,
  error: "",
  fixLength: 0,
  cols:"5",
  rows:"5",
  helperText: "",
  label: "",
  minLength: 0,
  maxLength: 0,
  placeholder: "",
  reqType: "",
  style: {},
  type: "text",
  validationHandler: () => {},
};

TextField.propTypes = {
  checked: PropTypes.bool,
  isKeywords: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fixLength: PropTypes.number,
  helperText: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  label: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  reqType: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  validationHandler: PropTypes.func,
  value: PropTypes.any.isRequired,
};

export default TextField;