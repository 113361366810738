import React, { useCallback } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout }
  from '@stripe/react-stripe-js';
// import { useLoginResult } from '../State/LoginResultContext';
import { STRIPE_API_KEY } from "../utils/constants";

const stripePromise = loadStripe(STRIPE_API_KEY);

const Checkout = () => {
  // const { loginResults } = useLoginResult();
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return fetch("http://localhost:4242/create-checkout-session", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default Checkout;
