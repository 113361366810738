import React, { createContext, useContext, useEffect, useReducer } from 'react';

const initialState = null;

let reducer = (_info, newInfo) => {
  if (newInfo === null) {
    localStorage.removeItem("evs");
    return initialState;
  }
  return newInfo;
};


const localState = JSON.parse(localStorage.getItem("evs"));

const EventResultContext = createContext();

export const EventResultsProvider = ({ children }) => {
  const [eventResults, seteventResults] = useReducer(reducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem("evs", JSON.stringify(eventResults));
  }, [eventResults]);

  const updateeventResults = (results) => {
    seteventResults(results);
  };

  return (
    <EventResultContext.Provider value={{ eventResults, updateeventResults }}>
      {children}
    </EventResultContext.Provider>
  );
};

export const useEventResult = () => {
  return useContext(EventResultContext);
};
